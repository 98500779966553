import { getParams } from "../helpers";
import withCache from "../helpers/cache";

import myFetch from "./myFetch";

export const loadProgramTemplates = () => {
  return myFetch("/api/program-templates/");
};

export const loadProgramTemplatesWithCache = withCache(loadProgramTemplates);

export const loadProgramTemplatesForCurrentUser = ({ page, search }) => {
  const params = getParams({ page, search });
  return myFetch(`/api/program-templates/current-user/${params && `?${params}`}`);
};

export const loadProgramTemplatesForCurrentUserWithCache = withCache(
  loadProgramTemplatesForCurrentUser
);

export const loadCurrentProgramTemplate = (id) => {
  return myFetch(`/api/program-templates/${id}/`);
};

export const loadCurrentProgramTemplateWithCache = withCache(
  loadCurrentProgramTemplate
);

export const addProgramTemplate = ({ body }) => {
  loadProgramTemplatesWithCache.invalidate();
  loadProgramTemplatesForCurrentUserWithCache.invalidate();
  return myFetch("/api/program-templates/", {
    method: "POST",
    body: body,
  });
};

export const editProgramTemplate = ({ id, body }) => {
  loadProgramTemplatesWithCache.invalidate();
  loadProgramTemplatesForCurrentUserWithCache.invalidate();
  loadCurrentProgramTemplateWithCache.invalidateByArgs(id);
  loadProgramPreviewWithCache.invalidateByArgs(id);
  return myFetch(`/api/program-templates/${id}/`, {
    method: "PATCH",
    body: body,
  });
};

export const deleteProgramTemplate = (id) => {
  loadProgramTemplatesWithCache.invalidate();
  loadProgramTemplatesForCurrentUserWithCache.invalidate();
  loadCurrentProgramTemplateWithCache.invalidateByArgs(id);
  return myFetch(`/api/program-templates/${id}/`, {
    method: "DELETE",
  });
};

export const checkTotalReps = ({ weight, reps, id }) => {
  return myFetch(
    `/api/exercises/${id}/set-detail?weight_percent=${weight}&reps=${reps}`
  );
};

const loadProgramPreview = (id) => {
  return myFetch(`/api/program-templates/${id}/preview/`);
};

export const loadProgramPreviewWithCache = withCache(loadProgramPreview);
