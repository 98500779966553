import { CLIENT_INFO_TYPES } from "../constants/clientApi";
import withCache from "../helpers/cache";

import myFetch, {
  emailHandlerError,
  activateClientError,
  sendBodyTestHandlerError,
} from "./myFetch";
import {
  loadActiveClientsWithCache,
  loadAllClientsWithCache,
  loadTrainersWithCache,
} from "./trainerApi";

export const loadClientList = ({ page, search = "", companyId }) => {
  const params = `${
    !!page ? `page=${page}` : ""}${
    !!search ? `&search=${search}` : ""}${
    companyId ? `&company_id=${companyId}` : ""
  }`;

  return myFetch(`/api/clients/?${params}`);
};

export const loadClientById = ({ id, infoType }) => {
  return myFetch(`/api/clients/${id}/${infoType ? `${infoType}/` : ""}`);
};

export const loadClientListWithCache = withCache(loadClientList);
export const loadClientByIdWithCache = withCache(loadClientById);

export const addClient = (body) => {
  loadClientListWithCache.invalidate();
  return myFetch("/api/clients/", { method: "POST", body }, emailHandlerError);
};

export const sendBodyTest = ({ body, id }) => {
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.BODY,
  });
  loadGoalProgressionWithCash.invalidateByArgs({ id: +id });
  return myFetch(
    `/api/clients/${id}/body/`,
    { method: "POST", body },
    sendBodyTestHandlerError
  );
};

export const sendStrengthTest = ({ body, id }) => {
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.BODY,
  });
  loadGoalProgressionWithCash.invalidateByArgs({ id: +id });
  const res = myFetch(
    `/api/clients/${id}/strength/`,
    { method: "POST", body },
    sendBodyTestHandlerError
  );
  return res;
};

export const sendDoubleStrengthTest = ({ actualData, estimateData, id }) => {
  loadClientByIdWithCache.invalidateByArgs({
    id,
    infoType: CLIENT_INFO_TYPES.BODY,
  });
  loadGoalProgressionWithCash.invalidateByArgs({ id: +id });
  return myFetch(
    `/api/clients/${id}/strength/double_test/`,
    { method: "POST", body: { actual: actualData, estimated: estimateData } },
    sendBodyTestHandlerError
  );
};

export const editMainInfoClient = ({ id, body }) => {
  loadClientListWithCache.invalidate();
  loadClientByIdWithCache.invalidateByArgs({
    id: String(id),
    infoType: CLIENT_INFO_TYPES.MAIN,
  });
  loadClientByIdWithCache.invalidateByArgs({
    id: String(id),
    infoType: CLIENT_INFO_TYPES.BODYMAIN,
  });

  loadTrainersWithCache.invalidate();
  loadActiveClientsWithCache.invalidate();
  loadAllClientsWithCache.invalidate();
  return myFetch(
    `/api/clients/${id}/main/`,
    {
      method: "PATCH",
      body: body,
    },
    emailHandlerError
  );
};

export const updateClientActiveHistory = ({ id, body }) => {
  loadClientListWithCache.invalidate();
  loadClientByIdWithCache.invalidateByArgs({
    id: +id,
    infoType: CLIENT_INFO_TYPES.MAIN,
  });
  loadClientByIdWithCache.invalidateByArgs({
    id: +id,
    infoType: CLIENT_INFO_TYPES.BODY,
  });
  loadClientByIdWithCache.invalidateByArgs({
    id: + id,
    infoType: CLIENT_INFO_TYPES.BODYMAIN,
  });

  loadTrainersWithCache.invalidate();
  loadActiveClientsWithCache.invalidate();
  loadAllClientsWithCache.invalidate();
  return myFetch(
    `/api/clients/${id}/`,
    {
      method: "PATCH",
      body: body,
    },
    activateClientError
  );
};

export const editMainInfoClientWithOutSave = ({ id, body }) =>
  myFetch(
    `/api/clients/${id}/main/`,
    {
      method: "PATCH",
      body: body,
    },
    emailHandlerError
  );

export const updateClientGoal = ({ id, goal }) => {
  loadClientByIdWithCache.invalidate();
  loadGoalProgressionWithCash.invalidateByArgs({ id: +id });
  return myFetch(`/api/clients/${id}/goal/value/`, {
    method: "PUT",
    body: goal,
  });
};

export const updateClientGoalDate = ({ id, goal }) => {
  loadClientByIdWithCache.invalidateByArgs({ id });
  loadGoalProgressionWithCash.invalidateByArgs({ id: +id });
  return myFetch(`/api/clients/${id}/goal/value/`, {
    method: "PATCH",
    body: goal,
  });
};

export const getClientProgress = ({ id, needLatest, offset, page, limit }) => {
  const needLatestParam = needLatest ? "&include_latest=true" : "";
  const offsetParam = offset ? `&offset=${offset}` : "";
  const pageParam = page ? `&page=${page}` : "";
  const limitParam = limit ? `&limit=${limit}` : "";
  const params = pageParam + offsetParam + needLatestParam + limitParam;

  return myFetch(`/api/clients/${id}/progress-history/?${params}`);
}

export const deleteClient = ({ id }) => {
  loadClientListWithCache.invalidate();
  loadClientByIdWithCache.invalidateByArgs({ id });

  loadTrainersWithCache.invalidate();
  loadActiveClientsWithCache.invalidate();
  loadAllClientsWithCache.invalidate();
  return myFetch(`/api/clients/${id}/`, { method: "DELETE" });
};

export const loadClientActivity = ({ id, page }) => {
  const pageParam = page ? `&page=${page}` : "";
  const idParam = `user_id=${id}`;
  const params = idParam + pageParam;
  return myFetch(`/api/activity-logs/${params && `?${params}`}`);
};

export const loadClientActivityWithCash = withCache(loadClientActivity);

const loadGoalProgression = ({ id }) => {
  return myFetch(`/api/clients/${id}/goal/progression/`);
};

export const loadGoalProgressionWithCash = withCache(loadGoalProgression);
